var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"feedbackFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ProductListSelectBox',{attrs:{"errors":errors},model:{value:(_vm.formData.product),callback:function ($$v) {_vm.$set(_vm.formData, "product", $$v)},expression:"formData.product"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"positive aspect field","rules":"required|min_words:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"form-control",attrs:{"id":"experiencing","error-messages":errors,"hide-details":errors.length === 0,"label":"The positive aspect of our product","rows":"5","outlined":"","placeholder":"Describe what do you like about our product"},model:{value:(_vm.formData.positiveAspect),callback:function ($$v) {_vm.$set(_vm.formData, "positiveAspect", $$v)},expression:"formData.positiveAspect"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"negative aspect field","rules":"required|min_words:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"form-control",attrs:{"id":"expectation","error-messages":errors,"hide-details":errors.length === 0,"label":"The negative aspect of our product","rows":"5","outlined":"","placeholder":"Describe what you don't like about our product"},model:{value:(_vm.formData.negativeAspect),callback:function ($$v) {_vm.$set(_vm.formData, "negativeAspect", $$v)},expression:"formData.negativeAspect"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"First Name","placeholder":"Enter your first name","outlined":""},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Last Name","placeholder":"Enter your last name","outlined":""},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Company Name","placeholder":"Enter your company name","outlined":""},model:{value:(_vm.formData.companyName),callback:function ($$v) {_vm.$set(_vm.formData, "companyName", $$v)},expression:"formData.companyName"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"Email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"label":"Email","placeholder":"Enter your Email Address","outlined":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{staticClass:"checkbox-wrapper",attrs:{"name":"checkbox","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"error-messages":errors,"hide-details":errors.length === 0,"value":"true","label":"I agree to receive an email response."},model:{value:(_vm.formData.isAgree),callback:function ($$v) {_vm.$set(_vm.formData, "isAgree", $$v)},expression:"formData.isAgree"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('SubmitButton',{attrs:{"loading":_vm.loading}})],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }